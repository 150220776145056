/*
 Resize reCAPTCHA to fit width of container
 Since it has a fixed width, we're scaling
 using CSS3 transforms
 https://codepen.io/dloewen/pen/jbgeZj/
 ------------------------------------------
 captchaScale = containerWidth / elementWidth
 */
function scaleCaptcha(elementWidth) {
	
	// Width of the reCAPTCHA element, in pixels
	const reCaptchaWidth = 304;
	
	// Get the containing element's width
	const containerWidth = $('.form-group-g-recaptcha').width();
	
	// Only scale the reCAPTCHA if it won't fit
	// inside the container
	if (reCaptchaWidth > containerWidth) {
		
		// Calculate the scale
		const captchaScale = containerWidth / reCaptchaWidth;
		
		// Apply the transformation
		$('.g-recaptcha').css({
			'transform': 'scale(' + captchaScale + ')'
		});
		
	}
	
}

$(document).ready(function() {
	outdatedBrowser({
		lowerThan: 'IE11',
		languagePath: 'https://cdn.jsdelivr.net/npm/outdatedbrowser@1.1.5/outdatedbrowser/lang/en.html'
	});
});

$(function() {
	
	objectFitImages('img.cover-image', {
		watchMQ: true
	});
	
	// $(".position-item.col-md-6").prev('.col-md-6').next('.col-md-12').addClass("position-width");
	
	$(".sitemap-item.col-sm-12").prev('.col-sm-6').addClass("col-sm-12");
	
	$('.lazy').lazy({
		scrollDirection: 'vertical',
		effectTime: 0,
		effect: 'show',
		visibleOnly: false,
		enableThrottle: true,
		throttle: 450,
		// threshold: 0,
		placeholder: 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
		afterLoad: function(element) {
			element.addClass('lazy-loaded');
			
			objectFitImages('img.cover-image', {
				watchMQ: true
			});
			
		},
		asyncLoader: function(element, response) {
			setTimeout(function() {
				response(true);
				element.addClass('lazy-loaded');
				
				objectFitImages('img.cover-image', {
					watchMQ: true
				});
				
			}, 1000);
		}
	});
	
	$('ul.sf-menu').superfish({
		delay: 0,
		animation: {
			opacity: 'none',
			height: 'none'
		},
		speed: 'none',
		speedOut: 'none',
		pathClass: 'active-drop',
		hoverClass: 'menuHover',
		autoArrows: false
	});
	
	if ($('.site-hero').hasClass('site-hero-only-one')) {
		const swiper = new Swiper('.site-hero-slideshow-galley', {
			direction: 'horizontal',
			loop: false,
			autoplay: 9000,
			speed: 600,
			spaceBetween: 0,
			slidesPerView: 1,
			effect: "slide",
			centeredSlides: true,
			keyboardControl: false,
			paginationClickable: false,
			parallax: false,
			pagination: false,
			simulateTouch: false,
			preloadImages: true,
			lazyLoadingInPrevNext: true,
			lazyLoading: true
		}).disableTouchControl();
	}
	else if ($('.site-hero').hasClass('site-hero-poster')) {
		const siteHeroSlidesCount = $('.site-hero.site-hero-poster .site-hero-slide').length;
		const swiper = new Swiper('.site-hero-slideshow-galley', {
			direction: 'horizontal',
			loop: false,
			autoplay: 9000,
			speed: 600,
			spaceBetween: 0,
			slidesPerView: siteHeroSlidesCount,
			effect: "slide",
			centeredSlides: false,
			keyboardControl: false,
			paginationClickable: false,
			parallax: true,
			pagination: false,
			simulateTouch: false,
			preloadImages: true,
			lazyLoadingInPrevNext: true,
			lazyLoading: true
		});
	}
	else {
		const swiper = new Swiper('.site-hero-slideshow-galley', {
			direction: 'horizontal',
			nextButton: '.site-hero-next',
			prevButton: '.site-hero-previous',
			loop: true,
			autoplay: 9000,
			speed: 600,
			spaceBetween: 0,
			slidesPerView: 1,
			effect: "slide",
			centeredSlides: true,
			keyboardControl: true,
			paginationClickable: true,
			parallax: true,
			simulateTouch: true,
			preloadImages: true,
			lazyLoadingInPrevNext: true,
			lazyLoading: true
		});
	}
	
	$(".poster-slideshow-galley").each(function() {
		
		const contentSlidesCount = $(this).find('.content-poster-slide').length;
		
		const swiperPoster = new Swiper(this, {
			direction: 'horizontal',
			loop: false,
			autoplay: 9000,
			speed: 600,
			spaceBetween: 0,
			slidesPerView: contentSlidesCount,
			effect: "slide",
			centeredSlides: false,
			keyboardControl: false,
			paginationClickable: false,
			parallax: true,
			pagination: false,
			simulateTouch: false,
			touchMoveStopPropagation: false,
			preloadImages: true,
			lazyLoadingInPrevNext: true,
			lazyLoading: true
		}).disableTouchControl();
		
	});
	
	////////////////////
	//// FancyBox ////
	/////////////////
	$("[data-fancybox]").fancybox({
		
		// Animation duration in ms
		speed: 450,
		
		// Horizontal space between slides
		gutter: 32,
		
		// Space around image, ignored if zoomed-in or viewport smaller than 800px
		margin: [64, 0],
		
		// Enable infinite gallery navigation
		loop: true,
		
		youtube: {
			showinfo: 0
		},
		
		// Should display toolbar (buttons at the top)
		toolbar: true,
		
		smallBtn: true,
		
		// What buttons should appear in the top right corner.
		// Buttons will be created using templates from `btnTpl` option
		// and they will be placed into toolbar (class="fancybox-toolbar"` element)
		buttons: [
			'slideShow',
			'fullScreen',
			'close'
		],
		
		// Enable keyboard navigation
		keyboard: true,
		
		// Should display infobar (counter and arrows at the top)
		infobar: true,
		
		iframe: {
			preload: false
		},
		
		// Image
		image: {
			protect: true
		},
		
		// Loading indicator template
		spinnerTpl: '<div class="fancybox-loading"></div>',
		
		// Open/close animation type
		// Possible values:
		//   false            - disable
		//   "zoom"           - zoom images from/to thumbnail
		//   "fade"
		//   "zoom-in-out"
		//
		animationEffect: "fade",
		
		// Duration in ms for open/close animation
		animationDuration: 600,
		
		// Slide Show
		slideShow: false,
		
		// Full Screen
		fullScreen: false,
		
		// Thumbnails
		thumbs: false,
		
		// Should image change opacity while zooming
		// If opacity is 'auto', then opacity will be changed if image and thumbnail have different aspect ratios
		zoomOpacity: 'auto'
		
	});
	
	// Initialize scaling
	scaleCaptcha();
	
	// Update scaling on window resize
	// Uses jQuery throttle plugin to limit strain on the browser
	$(window).resize($.throttle(100, scaleCaptcha));
	
	// Smooth Scrolling on Internal Links on a page
	$('nav.section-page-nav a[href^="#"]').on('click', function(e) {
		e.preventDefault();
		
		let target = this.hash;
		let $target = $(target);
		
		$('html, body').stop().animate({
			'scrollTop': $target.offset().top
		}, 900, 'swing');
		
	});
	
	$('.sidebar-list.sidebar-cal a[href^="#"]').on('click', function(e) {
		e.preventDefault();
		
		let target = this.hash;
		let $target = $(target);
		
		$('html, body').stop().animate({
			'scrollTop': $target.offset().top
		}, 900, 'swing');
		
	});
	
	
	
});

/////////////////
//// Search ////
//////////////
$(function() {
	$.typeahead({
		input: '.header-search-input',
		href: "{{url}}",
		display: ["title"],
		dynamic: true,
		emptyTemplate: "Your search for <strong>{{query}}</strong> did not return any results. Please make sure that all words are spelled correctly or try with some different keywords.",
		minLength: 3,
		maxItem: 10,
		filter: false,
		accent: true,
		cancelButton: false,
		highlight: false,
		searchOnFocus: true,
		backdropOnFocus: true,
		delay: 225,
		hint: false,
		
		source: {
			ajax: {
				url: "/search/suggestions.json",
				path: "pages",
				type: "GET",
				data: {
					q: "{{query}}"
				}
			}
		},
		
		callback: {
			onClickAfter: function(node, a, item, event) {
				window.location.href = item.url;
			}
		}
		
	});
});


///////////////////////
//// Hero Search ////
////////////////////
$(function() {
	$.typeahead({
		input: '.hero-search-input',
		display: ["title"],
		href: "{{url}}",
		dynamic: true,
		emptyTemplate: " Your search for <strong>{{query}}</strong> did not return any results. Please make sure that all words are spelled correctly or try with some different keywords.",
		minLength: 3,
		maxItem: 3,
		filter: false,
		accent: true,
		cancelButton: false,
		highlight: false,
		searchOnFocus: true,
		backdropOnFocus: true,
		delay: 225,
		hint: false,
		
		source: {
			ajax: {
				url: "/search/suggestions.json",
				path: "pages",
				type: "GET",
				data: {
					q: "{{query}}"
				}
			}
		},
		
		callback: {
			onClickAfter: function(node, a, item, event) {
				window.location.href = item.url;
			}
		}
		
	});
});
